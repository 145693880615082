<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    ({ props }) => (
        props.filled
            ? <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M19.0555 3.41708C19.7229 3.69364 20.3294 4.099 20.8401 4.60999C21.3511 5.12075 21.7565 5.72718 22.033 6.39464C22.3096 7.0621 22.4519 7.77751 22.4519 8.49999C22.4519 9.22248 22.3096 9.93789 22.033 10.6053C21.7565 11.2728 21.3511 11.8792 20.8401 12.39L19.7801 13.45L12.0001 21.23L4.22012 13.45L3.16012 12.39C2.12843 11.3583 1.54883 9.95903 1.54883 8.49999C1.54883 7.04096 2.12843 5.64169 3.16012 4.60999C4.19181 3.5783 5.59109 2.9987 7.05012 2.9987C8.50915 2.9987 9.90843 3.5783 10.9401 4.60999L12.0001 5.66999L13.0601 4.60999C13.5709 4.099 14.1773 3.69364 14.8448 3.41708C15.5122 3.14052 16.2276 2.99817 16.9501 2.99817C17.6726 2.99817 18.388 3.14052 19.0555 3.41708Z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            : <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M19.0555 3.41708C19.7229 3.69364 20.3294 4.099 20.8401 4.60999C21.3511 5.12075 21.7565 5.72718 22.033 6.39464C22.3096 7.0621 22.4519 7.77751 22.4519 8.49999C22.4519 9.22248 22.3096 9.93789 22.033 10.6053C21.7565 11.2728 21.3511 11.8792 20.8401 12.39L19.7801 13.45L12.0001 21.23L4.22012 13.45L3.16012 12.39C2.12843 11.3583 1.54883 9.95903 1.54883 8.49999C1.54883 7.04096 2.12843 5.64169 3.16012 4.60999C4.19181 3.5783 5.59109 2.9987 7.05012 2.9987C8.50915 2.9987 9.90843 3.5783 10.9401 4.60999L12.0001 5.66999L13.0601 4.60999C13.5709 4.099 14.1773 3.69364 14.8448 3.41708C15.5122 3.14052 16.2276 2.99817 16.9501 2.99817C17.6726 2.99817 18.388 3.14052 19.0555 3.41708Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

    )
)

</script>

<style lang="scss" scoped>

</style>
